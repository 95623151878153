import React, { useEffect, useState } from "react";
import moment from "moment";
import * as API from "../../Utils/Services/api";
import LatestNews from "../../Components/LatestNews/LatestNews";
import PlaceholderLoading from "react-placeholder-loading";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import "./News.css";
const News = () => {
  const id = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const resp = await API.GetNews();
      setNewsList(resp.data.news);
    })();
  }, []);

  return (
    <div className="container">
      <div className="latest-news">
        <h4>Latest Updates</h4>
        <div className="row">
          {newsList && newsList.length > 0
            ? newsList.map((item, index) => (
                <div key={index} className="col-lg-12 mb-2">
                  {/* <div
                      className="news-block new-news-flex-block"
                      onClick={() => navigate(`/newsdetails/${item.id}`)}
                    >
                      <div className="feauted-img">
                        <div className="featured-blog-img">
                          <img src={item.image.url} alt="category-img" />
                        </div>
                      </div>
                      <div className="news-box">
                        <div className="news-heading news-new-heading">
                          {item.title.slice(0, 100)}...
                        </div>
                        <div className="news-source">
                          {item.image.description.slice(0, 50)}...{" "}
                          <span> Read More</span>
                        </div>
                        <div className="news-time news-new-time">
                          {item.date}
                        </div>
                      </div>
                    </div> */}

                  <div
                    className="new-news-flex-block"
                    onClick={() => navigate(`/newsdetails/${item.slug}`)}
                  >
                    <div className="news-box news-new-boxxxx">
                      <div className="news-heading news-new-heading">
                        {item.title}
                      </div>
                      <div className="news-source new-news-sourceee">
                        {" "}
                        {item.content} <br />
                        {item.subTitle}
                        <span>Read More</span>
                      </div>
                      <div className="news-time news-new-time">
                        {moment(item.publishDate).format("MMM Do YY")}
                      </div>
                    </div>
                    <div className="new-img-newsss">
                      <img src={item.thumbnail} alt="category-img" />
                    </div>
                  </div>
                </div>
              ))
            : Array.from({ length: 7 }).map((_, index) => (
                <div key={index} className="col-lg-12 mb-1 ">
                  <div className="new-news-flex-block">
                    <div className="news-box news-new-boxxxx">
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                        style={{ marginTop: 10 }}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="70%"
                        height={20}
                        style={{ marginTop: 10 }}
                      />
                    </div>
                    <div className="new-img-newsss">
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={100}
                      />
                    </div>
                  </div>
                </div>
              ))}
        </div>

        {location.pathname !== "/news" && (
          <div className="button-more-articles-div">
            <NavLink to="/news">
              <button className="see-more-articles m-auto">
                See more news
              </button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
