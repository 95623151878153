import PlaceholderLoading from "react-placeholder-loading";

const PlaceholderPortfolioItem = () => {
  return [1, 2, 3].map((item, i) => (
    <div className="col-lg-4 col-md-4 mt-4">
      <div className="portfolio-item">
        <div className="token-info-add-new">
          <div className="token-name-img">
            <div className="image-token-portfolio-user">
              <PlaceholderLoading
                shape="circle"
                width={50}
                height={50}
                style={{ marginRight: "10px" }}
              />
            </div>
            <div className="token-name">
              <PlaceholderLoading shape="rect" width={100} height={20} />
            </div>
          </div>
          <div className="action-btn-portfolio">
            <div className="add-transaction-btn">
              <PlaceholderLoading shape="rect" width={30} height={30} />
            </div>
            <div className="remove-transaction-btn">
              <PlaceholderLoading shape="rect" width={30} height={30} />
            </div>
          </div>
        </div>
        <div className="portfolio-tokenandprice">
          <div className="token-info">
            <PlaceholderLoading shape="rect" width={50} height={20} />
          </div>
          <div className="token-price">
            <PlaceholderLoading shape="rect" width={100} height={20} />
          </div>
        </div>
        <div className="data-after-transactions-added">
          <div className="market-cap-portfolio">
            <div className="market-cap-heading-portfolio">
              <PlaceholderLoading shape="rect" width={80} height={20} />
            </div>
            <div className="market-cap-amt">
              <PlaceholderLoading shape="rect" width={50} height={20} />
            </div>
          </div>
          <div className="market-cap-portfolio">
            <div className="market-cap-heading-portfolio">
              <PlaceholderLoading shape="rect" width={80} height={20} />
            </div>
            <div className="market-cap-amt">
              <PlaceholderLoading shape="rect" width={50} height={20} />
            </div>
          </div>
          <div className="market-cap-portfolio">
            <div className="market-cap-heading-portfolio">
              <PlaceholderLoading shape="rect" width={80} height={20} />
            </div>
            <div className="market-cap-amt">
              <PlaceholderLoading shape="rect" width={50} height={20} />
            </div>
          </div>
        </div>
        <div className="market-fluctuations">
          <div className="oneHour">
            <div className="oneHr-title">
              <PlaceholderLoading shape="rect" width={30} height={20} />
            </div>
            <div className="oneHr-price text-end fw-bold">
              <PlaceholderLoading shape="rect" width={50} height={20} />
            </div>
          </div>
          <div className="twentyFourHour">
            <div className="oneHr-title">
              <PlaceholderLoading shape="rect" width={30} height={20} />
            </div>
            <div className="oneHr-price text-end fw-bold">
              <PlaceholderLoading shape="rect" width={50} height={20} />
            </div>
          </div>
          <div className="oneDay">
            <div className="oneHr-title">
              <PlaceholderLoading shape="rect" width={30} height={20} />
            </div>
            <div className="oneHr-price text-end fw-bold">
              <PlaceholderLoading shape="rect" width={50} height={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

export default PlaceholderPortfolioItem;
