import React from "react";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const BlogTable = ({ blogPosts, handleEdit, handleDelete }) => {
  const columns = [
    {
      name: <div>Title</div>,
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: <div>Author</div>,
      selector: (row) => row.author,
      sortable: true,
    },
    {
      name: <div>Category</div>,
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={() => handleEdit(row)}
            className="me-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            variant="link"
            className="text-danger"
            onClick={() => handleDelete(row._id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      // Removed `allowOverflow` and `button` props
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f7f8fa",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        overflowWrap: "break-word",
      },
    },
  };

  return (
    <div className="table-container">
      <DataTable
        columns={columns}
        data={blogPosts || []}
        noDataComponent="No blog posts available."
        customStyles={customStyles}
        highlightOnHover
        striped
      />
    </div>
  );
};

export default BlogTable;
