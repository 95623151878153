import "./Dashboard.css";

const PortfolioSummary = ({ totalAmount, totalPercentage, totalReturn }) => (
  <div className="overall-portfolio">
    <div className="col-lg-1"></div>
    <div className="col-lg-3 mt-3 col-md-4 col-12">
      <div className="portfolio-item">
        <div className="portfolio-amt">
          <b>
            $
            {totalAmount
              ? Number(totalAmount.toFixed(2)).toLocaleString()
              : "---"}
          </b>
        </div>
        <div className="current-price-div">Total Amount Spent</div>
      </div>
    </div>
    <div className="col-lg-3 mt-3 col-md-4 col-l2">
      <div className="portfolio-item">
        <div
          className={`portfolio-amt ${
            totalPercentage > 0
              ? "price-fluctuations-up"
              : "price-fluctuations-down"
          }`}
        >
          {/* <b>
            {totalPercentage
              ? totalPercentage &&
                (totalPercentage > 0 ? (
                  <>
                    <i className="fa fa-caret-up me-1"></i>{" "}
                    {totalPercentage.toFixed(2)}
                  </>
                ) : (
                  <>
                    <i className="fa fa-caret-down me-1"></i>
                    {totalPercentage.toFixed(2)}
                  </>
                ))
              : "---"}
            %
          </b> */}
          <b>
            {totalPercentage > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {totalPercentage
              ? Number(Math.abs(totalPercentage.toFixed(2))).toLocaleString()
              : "---"}
            %
          </b>
        </div>
        <div className="current-price-div">Total Percentage</div>
      </div>
    </div>
    <div className="col-lg-3 mt-3 col-md-4 col-l2">
      <div className="portfolio-item">
        <div
          className={`portfolio-amt ${
            totalReturn > 0
              ? "price-fluctuations-up"
              : "price-fluctuations-down"
          }`}
        >
          {" "}
          {totalReturn > 0 ? (
            <i className="fa fa-caret-up me-1"></i>
          ) : (
            <i className="fa fa-caret-down me-1"></i>
          )}
          {/* <b>
            {" "}
            {totalReturn
              ? totalReturn &&
                (totalReturn > 0 ? (
                  <>
                    <i className="fa fa-caret-up me-1"></i>{" "}
                    ${totalReturn.toFixed(2)}
                  </>
                ) : (
                  <>
                    <i className="fa fa-caret-down me-1"></i>
                    ${totalReturn.toFixed(2)}
                  </>
                ))
              : "---"}
          </b> */}
          <b>
            $
            {totalReturn
              ? Number(Math.abs(totalReturn.toFixed(2))).toLocaleString()
              : "---"}
          </b>
        </div>
        <div className="current-price-div">Total Returns</div>
      </div>
    </div>{" "}
    <div className="col-lg-1"></div>
  </div>
);

export default PortfolioSummary;
