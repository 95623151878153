import AirdropBg from "../../Assets/img/airdrop-pg-main.webp";
import "./Airdrop.css";

const Airdrop = () => {
  return (
    <div className="airdrop-pg-container">
      <div className="airdrop-div">Airdrop is coming soon</div>
    </div>
  );
};

export default Airdrop;
