import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const BlogEditor = ({ value, onChange }) => {
  return (
    <div className="pb-4">
      <label className="mb-3">Sections</label>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        style={{ height: "300px" }}
        placeholder="Write your content here..."
        modules={{
          toolbar: [
            [{ header: ["1", "2", "3", "4"] }, { font: [] }],
            [{ size: ["small", false, "large", "huge"] }], // Add font size options
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline", "strike"],
            ["link", "image", "blockquote", "code-block"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        }}
      />
    </div>
  );
};

export default BlogEditor;
