import * as API from "../../Utils/Services/api";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import "./LatestNews.css";
import PlaceholderLoading from "react-placeholder-loading";
import { useEffect, useState } from "react";

const LatestNews = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const resp = await API.GetNews();
      // console.log("resp.data.news", resp.data.news);
      setNewsList(resp.data.news);
    })();
  }, []);
  return (
    <div className="container">
      <div className="latest-news">
        <h4>Latest RWA News</h4>
        <div className="row">
          {newsList && newsList.length > 0
            ? newsList.slice(0, 4).map((item, index) => (
                <div key={index} className="col-lg-3 ">
                  <div>
                    <div
                      className="news-block new-news-block"
                      // style={
                      //   backgroundImage: `linear-gradient(
                      //     rgba(48, 48, 48, 0.815),
                      //     rgba(0, 0, 0, 0.675)
                      //   ), url(${item.image.url})`,
                      //   backgroundSize: "cover",
                      // }}
                      onClick={() => navigate(`/newsdetails/${item.slug}`)}
                    >
                      {/* <div className="feauted-img">
                      <div className="featured-blog-img">
                        <img src={item.image.url} alt="category-img" />
                      </div>
                    </div> */}
                      <div className="news-box new-news-box">
                        <div className="news-heading news-new-heading">
                          {item.title.slice(0, 30)}...
                        </div>
                        <div className="news-source">
                          {item.subTitle.slice(0, 70)}...
                          <span> Read More</span>
                        </div>
                        <div className="news-time news-new-time">
                          {moment(item.publishDate).format("MMM Do YY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="col-lg-3 mb-2">
                  <div className="news-block">
                    <div>
                      {/* <div className="feauted-img">
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={200}
                      />
                    </div> */}
                      <div className="news-box">
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={20}
                        />
                        <PlaceholderLoading
                          shape="rect"
                          width="80%"
                          height={20}
                          style={{ marginTop: 10 }}
                        />
                        <PlaceholderLoading
                          shape="rect"
                          width="60%"
                          height={20}
                          style={{ marginTop: 10 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        {/* <div className="row">
        <div className="col-lg-3">
          <div className="news-block">
            <a href="/">
              <div className="feauted-img">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720530267/rwaNews_y2kbd1.webp"
                  alt=""
                />
              </div>
              <div className="news-box">
                <div className="news-heading">
                  Farcaster Frame Example & Tutorial: How to Build Your Own
                  Farcaster Frame
                </div>
                <div className="news-source">RWA Forum</div>
                <div className="news-time">10 mins ago</div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="news-block">
            <a href="/">
              <div className="feauted-img">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720530267/rwaNews_y2kbd1.webp"
                  alt=""
                />{" "}
              </div>
              <div className="news-box">
                <div className="news-heading">
                  Farcaster Frame Example & Tutorial: How to Build Your Own
                  Farcaster Frame
                </div>
                <div className="news-source">RWA Forum</div>
                <div className="news-time">10 mins ago</div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="news-block">
            <a href="/">
              <div className="feauted-img">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720530267/rwaNews_y2kbd1.webp"
                  alt=""
                />{" "}
              </div>
              <div className="news-box">
                <div className="news-heading">
                  Farcaster Frame Example & Tutorial: How to Build Your Own
                  Farcaster Frame
                </div>
                <div className="news-source">RWA Forum</div>
                <div className="news-time">10 mins ago</div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="news-block">
            <a href="/">
              <div className="feauted-img">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720530267/rwaNews_y2kbd1.webp"
                  alt=""
                />{" "}
              </div>
              <div className="news-box">
                <div className="news-heading">
                  Farcaster Frame Example & Tutorial: How to Build Your Own
                  Farcaster Frame
                </div>
                <div className="news-source">RWA Forum</div>
                <div className="news-time">10 mins ago</div>
              </div>
            </a>
          </div>
        </div>
      </div> */}
        {location.pathname !== "/news" && (
          <div className="button-more-articles-div">
            <NavLink to="/news">
              <button className="see-more-articles see-more-news-button m-auto">
                See more news <i className="fa-solid fa-xs fa-angles-right"></i>
              </button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default LatestNews;
