import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import PlaceholderLoading from "react-placeholder-loading";
import GridTokenNews from "../../Components/GridTokenDetails/GridTokenNews";
import "./BlogDetails.css";

import parse from "html-react-parser";

const BlogDetailed = () => {
  const location = useLocation();
  const data = location.state.data;

  // console.log("dataaaaaaaaaa", data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <section className="blog-grid-area">
        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="bocPost-wrapper">
              <article className="bocPost-item-wrapper mb-120">
                <div className="bocPost-item-thumb p-relative">
                  {/* <img src="./Images/" alt="" /> */}{" "}
                  <div className="blogDetails-img blog-img-detailed-pg">
                    {data.thumbnail ? (
                      <img src={data.thumbnail} alt="category-img" />
                    ) : (
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={300}
                      />
                    )}
                  </div>
                  <p>
                    {data.category || (
                      <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={20}
                      />
                    )}
                  </p>
                </div>
                <div className="bocPost-meta">
                  <span>
                    <i className="fa-solid fa-user"></i>
                    {data.author || (
                      <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={20}
                      />
                    )}
                  </span>
                  <span>
                    <i className="fa-regular fa-calendar-days"></i>
                    {moment(data.publishDate).format("MMM Do YY") || (
                      <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={20}
                      />
                    )}
                  </span>
                  {/* <span>
                    <a href="#">
                      <i className="fa-regular fa-comments"></i>
                      Comments (05)
                    </a>
                  </span> */}
                </div>
                <h3 className="bocPost-title">
                  {" "}
                  {data.title || (
                    <PlaceholderLoading shape="rect" width="100%" height={30} />
                  )}
                </h3>
                <p>
                  {data.subTitle || (
                    <>
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                    </>
                  )}
                </p>
                <div className="bocPost-blockquote p-relative">
                  <blockquote>
                    <p>
                      {data.blockQuote?.text || (
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={20}
                        />
                      )}
                    </p>
                    <cite>
                      {data.blockQuote?.cite || (
                        <PlaceholderLoading
                          shape="rect"
                          width={100}
                          height={20}
                        />
                      )}
                    </cite>
                  </blockquote>
                </div>
                <div>
                  {data.sections ? (
                    parse(data.sections)
                  ) : (
                    <>
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                      <PlaceholderLoading
                        shape="rect"
                        width="100%"
                        height={20}
                      />
                    </>
                  )}
                </div>
                {/* <p>
                {data.sections ? (
                  data.sections.map((info, i) => (
                    <div key={i}>
                      <h4>
                        <b>
                          {info.title || (
                            <PlaceholderLoading
                              shape="rect"
                              width="100%"
                              height={20}
                            />
                          )}
                        </b>
                      </h4>
                      <br />
                      <br />
                      {info.content.map((item, index) => (
                        <div key={index}>
                          <b>
                            {item.subTitle || (
                              <PlaceholderLoading
                                shape="rect"
                                width="100%"
                                height={20}
                              />
                            )}{" "}
                            <br />
                          </b>
                          {item.details || (
                            <>
                              <PlaceholderLoading
                                shape="rect"
                                width="100%"
                                height={20}
                              />
                              <PlaceholderLoading
                                shape="rect"
                                width="100%"
                                height={20}
                              />
                              <PlaceholderLoading
                                shape="rect"
                                width="100%"
                                height={20}
                              />
                            </>
                          )}
                          <br />
                          <br />
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <>
                    <PlaceholderLoading shape="rect" width="100%" height={20} />
                    <PlaceholderLoading shape="rect" width="100%" height={20} />
                    <PlaceholderLoading shape="rect" width="100%" height={20} />
                  </>
                )}
              </p> */}
                {/* <p>
                {data.conclusion || (
                  <>
                    <PlaceholderLoading shape="rect" width="100%" height={20} />
                    <PlaceholderLoading shape="rect" width="100%" height={20} />
                    <PlaceholderLoading shape="rect" width="100%" height={20} />
                  </>
                )}
              </p> */}
              </article>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <GridTokenNews />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogDetailed;
