import "./GridTokenDetail.css";
import "./../../Pages/TokenDetails/TokenDetail.css";
const GridTokenDetail = ({
  name,
  image,
  price,
  marketCap,
  volumeDay,
  volumeChange,
  marketChange,
  maxSupply,
  circulatingSupply,
  totalSupply,
  fullyDilutedMarketCap,
  symbol,
  totalVolume,
  marketCapChange24H,
  githubLink = null,
  websiteLink = null,
  tokeninfo,
  whitepaper = null,
  twitterLink = null,
  telegramLink = null,
  discordLink = null,
}) => {
  return (
    <div className="">
      <div className="section-heading">
        <div
          className="section-top"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            // justifyContent: "left",
          }}
        >
          <img src={image} alt="" />
          <h4>{name}</h4>
          <b>({symbol})</b>
        </div>
        <div className="section-main-token">
          <h2>${price}</h2>{" "}
          <span
            className={`me-2 ${
              marketChange > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {marketChange > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {isNaN(marketChange) ? "--" : Math.abs(marketChange) + "%"}
          </span>
        </div>
      </div>
      <div className="add-buttons">
        <button>Add to wishlist</button>
        <button>Track in portfolio</button>
      </div>
      <div className="market-cap-details-grid">
        <div className="market-cap-heading">
          Market Cap
          <i className="fa fa-xs fa-info-circle ms-1"></i>
        </div>
        <div className="actual-market-cap">
          <span
            className={`me-2 ${
              marketChange > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {marketChange > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )}
            {isNaN(marketChange) ? "--" : Math.abs(marketChange) + "%"}
            {/* {Math.abs(marketChange)}% */}
          </span>
          ${marketCap}
        </div>
      </div>{" "}
      <div className="market-cap-details-grid market-cap-details-grid-2 mb-4">
        <div className="market-cap-heading-2">
          Total Volume
          <i className="fa fa-xs fa-info-circle ms-1"></i>
        </div>
        <div className="actual-market-cap">
          <span
            className={`me-2 ${
              volumeChange > 0
                ? "price-fluctuations-up"
                : "price-fluctuations-down"
            }`}
          >
            {/* {volumeChange > 0 ? (
              <i className="fa fa-caret-up me-1"></i>
            ) : (
              <i className="fa fa-caret-down me-1"></i>
            )} */}
            {/* {volumeChange}% */}
          </span>
          ${totalVolume}
        </div>
      </div>
      <div className="market-cap-total-supply">
        <div className="volume-cap">
          <div className="volume-cap-heading">Volume/Market cap (24h)</div>
          <div className="volume-cap-nom">{marketCapChange24H}%</div>
        </div>
        {/*  */}
        <div className="circulating-supply">
          <div className="circulating-supply-heading">Circulating supply </div>{" "}
          <div className="circulating-supply-nom">
            {circulatingSupply} {symbol}{" "}
          </div>
        </div>
        <div className="total-supply">
          <div className="total-supply-heading">Total supply </div>{" "}
          <div className="total-supply-nom">
            {totalSupply} {symbol}
          </div>
        </div>
        <div className="max-supply">
          <div className="max-supply-heading">Max. supply </div>
          <div className="max-supply-nom">
            {maxSupply} {symbol}{" "}
          </div>
        </div>
        <div className="fully-diluted-supply">
          <div className="fully-diluted-heading">Fully diluted valuation </div>
          <div className="fully-diluted-nom">${fullyDilutedMarketCap}</div>
        </div>
      </div>
      <div className="official-links">
        <div className="official-links-heading">Official Links</div>
        <div className="links-link">
          {websiteLink && (
            <a target="__blank" href={websiteLink}>
              <i className="fa fa-globe me-1"></i>Website
            </a>
          )}
          {whitepaper && (
            <a target="__blank" href={whitepaper}>
              {" "}
              <i className="fa-solid fa-note-sticky me-1"></i>Whitepaper
            </a>
          )}
          {githubLink && (
            <a target="__blank" href={githubLink}>
              {" "}
              <i className="fa-brands fa-github me-1"></i>Github
            </a>
          )}
        </div>
      </div>{" "}
      <div className="">
        <div className="official-links-heading">Social Links</div>
        <div className="social-links">
          <div>
            <a target="__blank" href={`https://x.com/${twitterLink}`}>
              <i className="fa-brands fa-square-x-twitter me-1"></i>Twitter
            </a>{" "}
          </div>{" "}
          {telegramLink && (
            <div>
              <a target="__blank" href={`https://t.me/${telegramLink}`}>
                {" "}
                <i className="fa-brands fa-telegram  me-1"></i>Telegram
              </a>{" "}
            </div>
          )}
          {discordLink && (
            <div>
              <a target="_-blank" href={discordLink}>
                {" "}
                <i className="fa-brands fa-discord  me-1"></i>Discord
              </a>{" "}
            </div>
          )}
          {/* <div>
            <a href="">
              {" "}
              <i className="fa-brands fa-youtube  me-1"></i>Reddit
            </a>
          </div> */}
        </div>
      </div>
      <p className="mt-3 token-info-grid">
        <b>{tokeninfo}</b>
      </p>
    </div>
  );
};

export default GridTokenDetail;
