import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "../../Utils/Services/api";

const Searchbar = ({ suggestions = [] }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    (async () => {
      if (debouncedSearchTerm) {
        const data = {
          page: 1,
          size: 30,
          category: debouncedSearchTerm,
        };
        const resp = await API.GetRWACurrencies(data);
        setTokenData(resp.data.currency);
      }
    })();
  }, [debouncedSearchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // Delay hiding to allow click on suggestion
    setTimeout(() => setIsFocused(false), 100);
  };

  const redirectHandler = (id) => {
    navigate(`/tokendetails/${id}`);
  };

  return (
    <div className="token-search">
      <div className="input-group">
        <span className="input-group-text">
          <i className="fa-solid fa-magnifying-glass"></i>
        </span>
        <input
          className="form-control"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>
      {searchTerm && (
        <div className="suggestions">
          {tokenData ? (
            tokenData.map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => redirectHandler(suggestion.id)}
              >
                <div className="suggestion-name">{suggestion.name}</div>
                <div className="suggestion-sym">
                  ({suggestion.symbol.toUpperCase()})
                </div>
              </div>
            ))
          ) : (
            <div className="suggestion-item">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};
export default Searchbar;
