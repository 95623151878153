import PlaceholderLoading from "react-placeholder-loading";

const PlaceholderPortfolioSummary = () => {
  return (
    <div className="overall-portfolio">
      <div className="col-lg-1"></div>
      <div className="col-lg-3 mt-3 col-md-4">
        <div className="portfolio-item">
          <div className="portfolio-amt">
            <PlaceholderLoading shape="rect" width={100} height={20} />
          </div>
          <div className="current-price-div">
            <PlaceholderLoading shape="rect" width={150} height={20} />
          </div>
        </div>
      </div>
      <div className="col-lg-3 mt-3 col-md-4">
        <div className="portfolio-item">
          <div className="portfolio-amt">
            <PlaceholderLoading shape="rect" width={100} height={20} />
          </div>
          <div className="current-price-div">
            <PlaceholderLoading shape="rect" width={150} height={20} />
          </div>
        </div>
      </div>
      <div className="col-lg-3 mt-3 col-md-4">
        <div className="portfolio-item">
          <div className="portfolio-amt">
            <PlaceholderLoading shape="rect" width={100} height={20} />
          </div>
          <div className="current-price-div">
            <PlaceholderLoading shape="rect" width={150} height={20} />
          </div>
        </div>
      </div>
      <div className="col-lg-1"></div>
    </div>
  );
};

export default PlaceholderPortfolioSummary;
