import React from "react";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const AdminNewsTable = ({ newsPosts, handleEdit, handleDelete }) => {
  const columns = [
    {
      name: <div>Title</div>,
      selector: (row) => row.title || "Untitled",
      sortable: true,
      wrap: true,
    },
    {
      name: <div>Author</div>,
      selector: (row) => row.author || "Unknown",
      sortable: true,
    },
    {
      name: <div>Subtitle</div>,
      selector: (row) => row.subTitle || "No Subtitle",
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Publish Date</div>,
      selector: (row) =>
        row.publishDate
          ? new Date(row.publishDate).toLocaleDateString()
          : "No Date",
      sortable: true,
    },
    {
      name: "Thumbnail",
      cell: (row) => (
        <img
          src={row.thumbnail || "https://via.placeholder.com/50"}
          alt={row.title || "No Thumbnail"}
          width="50"
          height="50"
          style={{ borderRadius: "5px" }}
        />
      ),
      ignoreRowClick: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={() => handleEdit(row)}
            className="me-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            variant="link"
            className="text-danger"
            onClick={() => handleDelete(row._id || row._id)} // Support for _id or id
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  // Custom styles for DataTable
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f7f8fa",
        fontWeight: "bold",
        color: "#333",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        overflowWrap: "break-word",
      },
    },
  };

  // Filter newsPosts to exclude undefined or invalid entries
  const filteredNewsPosts =
    newsPosts?.filter((post) => post && (post._id || post.id)) || [];

  return (
    <div className="table-container admin-news-table-container">
      <DataTable
        title="Admin News"
        columns={columns}
        data={filteredNewsPosts}
        pagination
        highlightOnHover
        striped
        customStyles={customStyles}
        noDataComponent="No news posts available."
        paginationPerPage={5}
        responsive
      />
    </div>
  );
};

export default AdminNewsTable;
