import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as API from "../../Utils/Services/api";
import PlaceholderLoading from "react-placeholder-loading";
import "./FeaturedArticles.css";

const FeatuedArticles = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [blogList, setBlogList] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await API.GetBlog();
      setBlogList(resp.data.blog);
    })();
  }, []);
  const isMobile = 500;
  return (
    <div className="container">
      <div className="featured-articles">
        <h4>Featured Blogs</h4>
        <div className="row">
          {blogList
            ? blogList.slice(0, 3).map((item, index) => (
                <div
                  key={index}
                  className={`${
                    isMobile ? "mb-2 col-12" : ""
                  } col-lg-4 col-md-6`}
                >
                  <div className="articles-block">
                    <div
                      className="articles-box-flex"
                      onClick={() =>
                        navigate("/blogdetails", { state: { data: item } })
                      }
                    >
                      <div className="feauted-img featured-image">
                        {/* <img src="./Images/articles-img.webp" alt="" /> */}{" "}
                        <div className="featured-blog-img">
                          <img src={item.thumbnail} alt="category-img" />
                        </div>
                      </div>
                      <div className="articles-box articles-box-home-page">
                        {/* <p>API</p> */}
                        <div className="article-heading articles-heading-change-new">
                          {item.subTitle.slice(0, 50)}...
                        </div>
                        <div className="articles-author">{item.by}</div>
                        {/* <div className="articles-desc">{item.intro}</div> */}
                        <div className="news-source articles-paragrph articles-readmore">
                          {item.subTitle.slice(0, 140)}...
                          <span> Read More</span>
                        </div>
                        {/* <div className="articles-rating">
                  <i className="fa fa-star fa-xs pe-1"></i>
                  5.0 (4 votes)
                </div> */}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="col-lg-3 mb-4">
                  <div className="news-block">
                    <div>
                      <div className="feauted-img">
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={200}
                        />
                      </div>
                      <div className="news-box">
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={20}
                        />
                        <PlaceholderLoading
                          shape="rect"
                          width="80%"
                          height={20}
                          style={{ marginTop: 10 }}
                        />
                        <PlaceholderLoading
                          shape="rect"
                          width="60%"
                          height={20}
                          style={{ marginTop: 10 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        {location.pathname !== "/blogs" && (
          <div className="button-more-articles-div button-margin-top-more-blog">
            <NavLink to="/blogs">
              <button className="see-more-articles see-more-news-button m-auto see-more-blog-margin-top">
                See more blogs{" "}
                <i className="fa-solid fa-xs fa-angles-right"></i>
              </button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatuedArticles;
