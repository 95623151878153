import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

import "./Admin.css";
import AdminNavbar from "./AdminNavbar";
import BlogTable from "./BlogTable";
import {
  AdminAddBlog,
  AdminBlogFetch,
  AdminDeleteBlogs,
  AdminUpdateBlog,
} from "../Utils/Services/api";

import BlogEditor from "./QuilEditor/BlogEditor";

const categories = [
  "Real Estate",
  "Precious Metals",
  "Agriculture",
  "Gold",
  "Art",
  "Silver",
  "Rare Coins",
  "Luxury Assets",
];

const AdminBlogs = () => {
  const [sectionsContent, setSectionsContent] = useState("");

  const [blogPosts, setBlogPosts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sections: "",
    },
  });

  useEffect(() => {
    const fetchBlogPosts = async () => {
      setIsLoading(true);
      try {
        const response = await AdminBlogFetch({
          page: 1,
          size: 10,
          filter: "",
        });

        // console.log(response, "thiss");

        if (!response.data.status) {
          throw new Error("Failed to fetch blog posts");
        }

        setBlogPosts(response.data.blogs);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  const blogTitle = watch("title");

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, "-");
  };

  useEffect(() => {
    if (blogTitle) {
      const slug = generateSlug(blogTitle);
      setValue("slug", slug);
    }
  }, [blogTitle, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("slug", data.slug);
    formData.append("category", data.category);
    formData.append("author", data.author);
    formData.append("title", data.title);
    formData.append("subTitle", data.subTitle);
    formData.append("thumbnail", data.thumbnail[0]);
    formData.append(
      "blockQuote",
      JSON.stringify({
        text: data.blockQuoteText,
        cite: data.blockQuoteCite,
      })
    );
    formData.append("sections", sectionsContent);

    try {
      let response;
      if (currentPost) {
        // Update existing blog post
        response = await AdminUpdateBlog(currentPost._id, formData);

        // console.log(response, "resposne from blog update");
      } else {
        // Add new blog post
        response = await AdminAddBlog(formData);
      }

      if (!response.status) {
        throw new Error("Failed to save blog post.");
      }

      alert("Blog post saved successfully!");
      setShowModal(false);
      reset();

      // Refetch updated list of blog posts
      const updatedData = await AdminBlogFetch({
        page: 1,
        size: 10,
        filter: "",
      });
      setBlogPosts(updatedData.data.blogs);
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to save blog post. Please try again.");
    }
  };

  const handleEdit = (post) => {
    setCurrentPost(post);
    setShowModal(true);

    const blockQuote = post.blockQuote || { text: "", cite: "" };

    reset({
      slug: post.slug,
      category: post.category,
      author: post.author,
      title: post.title,
      subTitle: post.subTitle,
      blockQuoteText: blockQuote.text,
      blockQuoteCite: blockQuote.cite,
      sections: post.sections,
    });
    setSectionsContent(post.sections);
  };

  const handleDelete = async (id) => {
    try {
      await AdminDeleteBlogs(id);
      // Refetch updated list of blog posts after deletion
      const updatedData = await AdminBlogFetch({
        page: 1,
        size: 10,
        filter: "",
      });
      setBlogPosts(updatedData.data.blogs);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleAddPost = () => {
    setCurrentPost(null);
    setShowModal(true);
    reset({
      slug: "",
      thumbnail: "",
      category: "Real Estate",
      author: "",
      title: "",
      subTitle: "",
      blockQuoteText: "",
      blockQuoteCite: "",
      sections: "",
      conclusion: "",
    });
    setSectionsContent("");
  };

  return (
    <>
      <AdminNavbar />
      <div className="admin-dashboard-pg">
        <div className="container">
          <div className="form-admin-blogs">
            <h3>Blogs</h3>

            <div className="container">
              <div className="d-flex justify-content-end align-items-end">
                <Button
                  onClick={handleAddPost}
                  className="mb-3 add_post_button"
                >
                  Add Post
                </Button>
              </div>
            </div>

            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <BlogTable
                blogPosts={blogPosts}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            )}

            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              className="custom-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {currentPost ? "Edit Blog Post" : "Add Blog Post"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="form-admin-blog"
                >
                  <div>
                    <label className="me-2">Slug</label>
                    <input
                      type="text"
                      {...register("slug")}
                      readOnly
                      disabled
                    />
                    {errors.slug && (
                      <p className="error">{errors.slug.message}</p>
                    )}
                  </div>

                  <div>
                    <label className="me-2">Thumbnail Image</label>
                    <input
                      type="file"
                      {...register("thumbnail", {
                        required: "Thumbnail image is required",
                      })}
                    />
                    {errors.thumbnail && (
                      <p className="error">{errors.thumbnail.message}</p>
                    )}
                  </div>

                  <div>
                    <label className="me-2">Category</label>
                    <select
                      {...register("category", {
                        required: "Category is required",
                      })}
                    >
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                    {errors.category && (
                      <p className="error">{errors.category.message}</p>
                    )}
                  </div>

                  <div>
                    <label>Author</label>
                    <input
                      type="text"
                      {...register("author", {
                        required: "Author is required",
                      })}
                    />
                    {errors.author && (
                      <p className="error">{errors.author.message}</p>
                    )}
                  </div>

                  <div>
                    <label>Blog Title</label>
                    <input
                      type="text"
                      {...register("title", {
                        required: "Blog Title is required",
                      })}
                    />
                    {errors.title && (
                      <p className="error">{errors.title.message}</p>
                    )}
                  </div>

                  <div>
                    <label>SubTitle</label>
                    <textarea
                      {...register("subTitle", {
                        required: "SubTitle is required",
                      })}
                    />
                    {errors.subTitle && (
                      <p className="error">{errors.subTitle.message}</p>
                    )}
                  </div>

                  <div>
                    <label>Block Quote Text</label>
                    <textarea
                      {...register("blockQuoteText", {
                        required: "Block Quote Text is required",
                      })}
                    />
                    {errors.blockQuoteText && (
                      <p className="error">{errors.blockQuoteText.message}</p>
                    )}
                  </div>

                  <div>
                    <label>Block Quote Cite</label>
                    <input
                      type="text"
                      {...register("blockQuoteCite", {
                        required: "Cite is required",
                      })}
                    />
                    {errors.blockQuoteCite && (
                      <p className="error">{errors.blockQuoteCite.message}</p>
                    )}
                  </div>

                  <BlogEditor
                    value={sectionsContent}
                    onChange={setSectionsContent}
                  />

                  <div className="d-flex justify-content-center">
                    <button type="submit" className="submit_post">
                      {currentPost ? "Update" : "Create"}
                    </button>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBlogs;
