const AddCoinContent = ({
  searchList,
  handleInputChange,
  searchTerm,
  handleAddCoin,
  starToggle,
  userTokenSelected,
}) => (
  <>
    <div className="input-group mb-3">
      <span
        className="input-group-text"
        id="basic-addon1"
        style={{
          position: "absolute",
          top: "50%",
          zIndex: "3",
          color: "grey",
          left: "-2px",
        }}
      >
        <i className="fa fa-search fa-xs"></i>
      </span>
      <input
        type="text"
        className="form-control"
        placeholder="Search..."
        aria-label="Search..."
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
    <div className="trending-searches-div">
      {searchList &&
        searchList.map((item, i) => (
          <div key={item.id}>
            <p
              className="trending-searches-p"
              onClick={() => handleAddCoin(item.id)}
            >
              <div className="token-img-search">
                <img src={item.image} alt="" />
              </div>
              <div className="token-name-search">
                <b>{item.name}</b>
                <span> ({item.symbol})</span>
              </div>
              <div className="token-star-search ms-auto">
                {userTokenSelected && userTokenSelected.includes(item.id) ? (
                  <i
                    className="fa fa-star fa-xs"
                    style={{ color: "orangeRed" }}
                  ></i>
                ) : (
                  <i className="fa-regular fa-star fa-xs"></i>
                )}
              </div>
            </p>
          </div>
        ))}
    </div>
  </>
);

export default AddCoinContent;
