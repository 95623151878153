import * as API from "../../Utils/Services/api";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./SetPassword.css";
import logo from "../../Assets/img/logo.png";

const SetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const submitForm = async (data) => {
    try {
      const payload = {
        token,
        pass: data,
      };
      const resp = await API.ResetPassword(payload);
      // console.log(resp.data);

      if (resp.data.status) {
        toast.success(resp.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        navigate("/login");
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const password = watch("password");
  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <div className="login-pg">
        <div className="navbar-brand">
          <a className="header-logo-main-login-page" href="/">
            <img src={logo} alt="logo" style={{ width: "120px" }} />
          </a>
        </div>

        <div className="container">
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="login-form">
              <h4>RESET PASSWORD</h4>
              <div className="login-form-details mt-4">
                <div className="new-password-setup">
                  <div className="password-credentials">
                    <div className="forgot-password">
                      <p className="pb-2">ENTER NEW PASSWORD</p>
                    </div>
                    <div className="input-container">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className="input-with-icon"
                        onChange={inputHandler}
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                      />{" "}
                      {isPasswordVisible ? (
                        <i
                          className="fa-solid fa-eye"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                    </div>{" "}
                    {errors.password && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          letterSpacing: "2.4px",
                        }}
                      >
                        {errors.password.message}
                      </p>
                    )}
                  </div>{" "}
                  <div className="password-credentials">
                    <div className="forgot-password">
                      <p className="pb-2">CONFIRM PASSWORD</p>
                    </div>
                    <div className="input-container">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        name="confirmPassword"
                        onChange={inputHandler}
                        placeholder="Confirm Password"
                        className="input-with-icon"
                        {...register("confirmPassword", {
                          required: "Please confirm your password",
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        })}
                      />
                      {isConfirmPasswordVisible ? (
                        <i
                          className="fa-solid fa-eye"
                          onClick={toggleConfirmPasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-eye-slash"
                          onClick={toggleConfirmPasswordVisibility}
                        ></i>
                      )}
                    </div>{" "}
                    {errors.confirmPassword && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          letterSpacing: "2.4px",
                        }}
                      >
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                  <div className="sign-up-btn">
                    <button type="submit">Reset Password</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
