import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "../../Pages/Blogs/BlogDetails.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
  hover: {
    mode: null,
  },
  maintainAspectRatio: false, // Add this option
};

export default function SevenDays({ price, datum }) {
  const labels = datum;
  const gainOrLoss = price > 0 ? "rgb(11, 218, 81)" : "rgb(255, 99, 132)";
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: datum,
        borderColor: gainOrLoss,
        borderWidth: 1,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };
  return (
    <div className="graph-dynamic">
      <Line options={options} data={data} />
    </div>
  );
}
