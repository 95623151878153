import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../Pages/Dashboard/Dashboard.css";
const CustomModal = ({ show, onHide, title, bodyContent, footerContent }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-portfolio"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 style={{ marginBottom: "0" }}>
            {" "}
            <b>{title}</b>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyContent}</Modal.Body>
      <Modal.Footer>{footerContent}</Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
