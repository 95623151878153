import { Button } from "react-bootstrap";

const RemoveTransaction = ({ removeTokenFooterContent }) => {
  return (
    <>
      <div>Are you sure you want to remove this token from your portfolio?</div>
      <div className="m-auto submit-btn">{removeTokenFooterContent}</div>
    </>
  );
};

export default RemoveTransaction;
