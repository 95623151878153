import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import LogoHeader from "../Assets/img/rwa-logo-footer.png";
import ForgetPassModal from "../Common/Modal/ForgetPassModal/ForgetPassModal";

import { AdminLoginAPI } from "../Utils/Services/api";

const AdminLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // If token aviable for admin navigate to AdminDahsboard
  useEffect(() => {
    if (localStorage.getItem("token")) navigate("/adminDashboard");
  }, [navigate]);

  const onsubmit = async (data) => {
    try {
      setIsLoading(true);
      setLoginError("");

      const response = await AdminLoginAPI(data);

      if (response.status === 200) {
        // Store the token in localStorage
        localStorage.setItem("token", response.data.token);

        setIsLoading(false);
        // Redirect to AdminDashboardPage
        navigate("/adminDashboard");
      }
    } catch (error) {
      if (error.response) {
        setLoginError(error.response.data.message || "Login failed.");
        setIsLoading(false);
      } else {
        setIsLoading(false);

        setLoginError("An error occurred. Please try again.");
      }
      console.error("Login error:", error);
    }
  };

  const inputHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onPreview = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="admin-dashboard-main-pg">
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="login-pg">
          <div className="navbar-brand">
            <a className="header-logo-main-login-page" href="/">
              <img src={LogoHeader} alt="logo" style={{ width: "120px" }} />
            </a>
          </div>
          <div className="container">
            <form onSubmit={handleSubmit(onsubmit)}>
              <div className="login-form">
                <h4>ADMIN LOGIN</h4>
                {loginError && <p style={{ color: "red" }}>{loginError}</p>}
                <div className="login-form-details mt-4">
                  <div className="email-credentials">
                    <p className="pb-2">EMAIL</p>
                    <input
                      type="text"
                      placeholder="Email Address"
                      onChange={inputHandler}
                      name="email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Enter a valid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email.message}</p>
                    )}
                  </div>
                  <div className="password-credentials">
                    <div className="forgot-password">
                      <p className="pb-2">PASSWORD</p>
                      <div className="forgot-password-modal">
                        <button
                          type="button"
                          className="forgot-pass pb-2"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          Forgot password?
                        </button>
                      </div>
                    </div>
                    <div className="input-container">
                      <input
                        type={isVisible ? "text" : "password"}
                        onChange={inputHandler}
                        placeholder="Password"
                        className="input-with-icon"
                        name="password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                      />
                      <i
                        className={`fa-solid ${
                          isVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={onPreview}
                      ></i>
                    </div>
                    {errors.password && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          letterSpacing: "2.4px",
                        }}
                      >
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <div className="sign-up-btn">
                    <button type="submit" disabled={isLoading}>
                      {isLoading ? "Loading..." : "LOGIN"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ForgetPassModal />
      </div>
    </div>
  );
};

export default AdminLogin;
