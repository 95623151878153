import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const EditTransactionDashboard = ({ tokenId, handleTransactionSubmit }) => {
  const [inputTotalSpent, setInputTotalSpent] = useState(tokenId.amount || 0);
  const [inputTokenQuantity, setInputTokenQuantity] = useState(
    tokenId.quantity || 0
  );
  const [inputPerUnit, setInputPerUnit] = useState(tokenId.perUnit || 0);

  useEffect(() => {
    if (inputTotalSpent && inputTokenQuantity) {
      setInputPerUnit(inputTotalSpent / inputTokenQuantity);
    }
  }, [inputTotalSpent, inputTokenQuantity]);

  const onsubmit = async () => {
    const data = {
      id: tokenId.tokenId,
      amount: inputTotalSpent,
      quantity: inputTokenQuantity,
    };
    try {
      const isSuccess = await handleTransactionSubmit(data);
      if (isSuccess) {
        toast.success("Transaction Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Failed to update transaction. Please try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="basic-information">
      <label htmlFor="select_coin">Selected Coin</label>
      <p>
        <b>{tokenId?.name}</b>
      </p>
      <label htmlFor="total_spent">Total Spent</label>
      <input
        type="text"
        id="total_spent"
        name="total_spent"
        value={inputTotalSpent}
        onChange={(e) => setInputTotalSpent(e.target.value)}
      />
      <div className="quantityandPriceperCoin">
        <div className="quantity">
          <label htmlFor="quantity_token">Quantity</label>
          <input
            type="number"
            id="quantity_token"
            name="quantity_token"
            value={inputTokenQuantity}
            onChange={(e) => setInputTokenQuantity(e.target.value)}
          />
        </div>
        <div className="price-per-token">
          <label htmlFor="price-per-token">Price per token</label>
          <input
            type="number"
            id="price-per-token"
            name="price-per-token"
            value={inputPerUnit}
            disabled
          />
        </div>
      </div>
      <div className="submit-btn mt-5">
        <button onClick={onsubmit}>Save Changes</button>
      </div>
    </div>
  );
};

export default EditTransactionDashboard;
