import "./Faq.css";

const Faq = () => {
  return (
    <div className="about-pg">
      <h3>Frequently Asked Questions</h3>
      <div className="about-rwa">
        <div className="about-about-rwa">
          <b>
            RWA.GUIDE is the world's most-referenced platform for tracking and
            analyzing Real World Assets (RWA) in the burgeoning blockchain
            space. Our mission is to make real-world assets discoverable and
            efficient globally by empowering users with unbiased, high-quality,
            and accurate information, enabling them to draw their own informed
            conclusions.
          </b>
        </div>
        <div className="rwacamp-about">
          <h6>What are Real World Assets (RWA)?</h6>
          <p>
            Real World Assets (RWA) refer to tangible assets such as real
            estate, precious metals, collectibles, agricultural assets, natural
            resources, and other physical commodities. These assets can be
            tokenized on a blockchain to facilitate easier trading, fractional
            ownership, and increased liquidity.
          </p>
          <h6>How are the prices of tokenized RWAs determined?</h6>
          <p>
            The prices of tokenized RWAs are determined by the market dynamics
            of supply and demand. Additionally, factors such as the underlying
            value of the asset, market conditions, and investor sentiment play a
            significant role in price determination.
          </p>
          <h6>
            What is "Market Capitalization" and how is it calculated for RWAs?
          </h6>
          <p>
            Market Capitalization is a metric used to rank the relative size of
            a tokenized RWA. It's calculated by multiplying the token price by
            the circulating supply of tokens.
          </p>
          <h6>
            What is the difference between "Circulating Supply", "Total Supply",
            and "Max Supply" for tokens?
          </h6>
          <p>
            Circulating Supply: The best approximation of the number of tokens
            currently circulating in the market and in the general public's
            hands. Total Supply: The total amount of tokens in existence right
            now (minus any tokens that have been verifiably burned). Max Supply:
            The best approximation of the maximum amount of tokens that will
            ever exist in the lifetime of the token.
          </p>
          <h6 className="text-center">
            For further information about RWA, please visit our website
            <a href="https://rwa.chat/" target="__blank" className="ms-2">
              RWA.CHAT
            </a>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Faq;
