import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import * as API from "../../Utils/Services/api";
import "./Dashboard.css";

const initialFormValues = {
  nameToken: "",
  symbolToken: "",
  descriptionToken: "",
  rwaCategory: [],
  website: "",
  whitepaper: "",
  submitter: "",
  tokenIssue: "",
  tokenSupply: "",
  twitter: "",
  telegram: "",
  facebook: "",
  youtube: "",
  subreddit: "",
  github: "",
  bitbucket: "",
  additionalInfo: "",
  tokenImage: null,
  listingTerm: false,
  supportTerm: false,
  contractAddress: "",
  contractDecimals: "",
  exchangeTradeUrl: "",
  explorerLink1: "",
  explorerLink2: "",
  explorerLink3: "",
};

const TokenForm = () => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   if (type === "checkbox") {
  //     const updatedChecked = checked
  //       ? [...formValues.rwaCategory, value]
  //       : formValues.rwaCategory.filter((item) => item !== value);
  //     setFormValues({
  //       ...formValues,
  //       rwaCategory: updatedChecked,
  //     });
  //   } else {
  //     setFormValues({
  //       ...formValues,
  //       [name]: value,
  //     });
  //   }
  // };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      const updatedChecked = checked
        ? [...formValues.rwaCategory, value]
        : formValues.rwaCategory.filter((item) => item !== value);
      setFormValues({
        ...formValues,
        [name]: checked,
        rwaCategory: updatedChecked,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleFileChange = (e) => {
    setFormValues({
      ...formValues,
      tokenImage: e.target.files[0],
    });
  };

  const validate = () => {
    let errors = {};

    if (!formValues.nameToken) {
      errors.nameToken = "Name of token is required";
    }
    if (!formValues.symbolToken) {
      errors.symbolToken = "Symbol of token is required";
    }
    if (!formValues.website) {
      errors.website = "Website URL is required";
    }
    if (!formValues.submitter) {
      errors.submitter = "Submitter's role is required";
    }
    if (!formValues.tokenImage) {
      errors.tokenImage = "Token image is required";
    }
    if (!formValues.listingTerm) {
      errors.listingTerm = "Listing terms should be checked";
    }
    if (!formValues.supportTerm) {
      errors.supportTerm = "Support terms should be checked";
    }
    // if (!formValues.contractAddress) {
    //   errors.contractAddress = "Contract Address is required";
    // }
    // if(!formValues.exchangeTradeUrl){
    //   errors.exchangeTradeUrl = "Exchange Change Url is required"
    // }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const errors = validate();
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        let formData = new FormData();
        formData.append("nameToken", formValues.nameToken);
        formData.append("symbolToken", formValues.symbolToken);
        formData.append("rwaCategory", formValues.rwaCategory);
        formData.append("website", formValues.website);
        formData.append("submitter", formValues.submitter);
        formData.append("listingTerm", formValues.listingTerm);
        formData.append("supportTerm", formValues.supportTerm);
        formData.append("tokenImage", formValues.tokenImage);
        formData.append("descriptionToken", formValues.descriptionToken);
        formData.append("whitepaper", formValues.whitepaper);
        formData.append("tokenIssue", formValues.tokenIssue);
        formData.append("tokenSupply", formValues.tokenSupply);
        formData.append("twitter", formValues.twitter);
        formData.append("telegram", formValues.telegram);
        formData.append("facebook", formValues.facebook);
        formData.append("youtube", formValues.youtube);
        formData.append("subreddit", formValues.subreddit);
        formData.append("github", formValues.github);
        formData.append("bitbucket", formValues.bitbucket);
        formData.append("additionalInfo", formValues.additionalInfo);
        formData.append("contractAddress", formValues.contractAddress);
        formData.append("contractDecimals", formValues.contractDecimals);
        formData.append("exchangeTradeUrl", formValues.exchangeTradeUrl);
        formData.append("explorerLink1", formValues.explorerLink1);
        formData.append("explorerLink2", formValues.explorerLink2);
        formData.append("explorerLink3", formValues.explorerLink3);

        setIsSubmitting(false);
        // Submit form logic
        const resp = await API.AddToken(formData);
        // console.log("resp.data", resp.data);
        if (resp.data.status) {
          toast.success(resp.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 mt-3 mb-5 m-auto">
          <div className="form-container">
            <h1 className="token-form-heading">
              New Token Listing Request Form
            </h1>
            <p>Submit this form to request the listing of your new token.</p>
            <form
              id="tokenForm"
              onSubmit={handleSubmit}
              autoComplete="off"
              enctype="multipart/form-data"
            >
              <div className="basic-information">
                <h3>Basic Information</h3>
                <h6>
                  Token Name <span>*</span>
                </h6>
                <label htmlFor="nameToken">Name of the token</label> <br />
                <input
                  type="text"
                  id="nameToken"
                  name="nameToken"
                  value={formValues.nameToken}
                  onChange={handleInputChange}
                />
                {formErrors.nameToken && (
                  <div className="error">{formErrors.nameToken}</div>
                )}
                <h6 className="mt-4">
                  Token Symbol <span>*</span>
                </h6>
                <label htmlFor="symbolToken">Symbol of the token</label> <br />
                <input
                  type="text"
                  id="symbolToken"
                  name="symbolToken"
                  value={formValues.symbolToken}
                  onChange={handleInputChange}
                />
                {formErrors.symbolToken && (
                  <div className="error">{formErrors.symbolToken}</div>
                )}
                <h6 className="mt-3">
                  RWA Category <span>*</span>
                </h6>
                <label htmlFor="checked">Select Your RWA Category </label>{" "}
                <br />
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="checkboxcategory"
                >
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Art"
                      checked={formValues.rwaCategory.includes("Art")}
                      onChange={handleInputChange}
                    />
                    Art
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Liquidity"
                      checked={formValues.rwaCategory.includes("Liquidity")}
                      onChange={handleInputChange}
                    />
                    Liquidity
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Stablecoins"
                      checked={formValues.rwaCategory.includes("Stablecoins")}
                      onChange={handleInputChange}
                    />
                    Stablecoins
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Real Estate"
                      checked={formValues.rwaCategory.includes("Real Estate")}
                      onChange={handleInputChange}
                    />
                    Real Estate
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Securities"
                      checked={formValues.rwaCategory.includes("Securities")}
                      onChange={handleInputChange}
                    />
                    Securities
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Commodities"
                      checked={formValues.rwaCategory.includes("Commodities")}
                      onChange={handleInputChange}
                    />
                    Commodities
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="Asset Management"
                      checked={formValues.rwaCategory.includes(
                        "Asset Management"
                      )}
                      onChange={handleInputChange}
                    />
                    Asset Management
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="checked"
                      value="MakerDAO"
                      checked={formValues.rwaCategory.includes("MakerDAO")}
                      onChange={handleInputChange}
                    />
                    MakerDAO
                  </label>
                </div>
                <h6 className="mt-4">Description</h6>
                <label htmlFor="descriptionToken">
                  Please provide a clear and concise description of the project
                  (eg. purpose, function, utility etc). Vague or "shilly"
                  description will result in listing DELAYS and/or REJECTION.
                </label>
                <br />
                <textarea
                  id="descriptionToken"
                  name="descriptionToken"
                  value={formValues.descriptionToken}
                  onChange={handleInputChange}
                />
                {/* {formErrors.descriptionToken && (
                <div className="error">{formErrors.descriptionToken}</div>
              )} */}
                <h6 className="mt-4">
                  Website URL<span>*</span>
                </h6>
                <label htmlFor="website">Website Guide</label> <br />
                <input
                  type="text"
                  id="website"
                  name="website"
                  value={formValues.website}
                  onChange={handleInputChange}
                />
                {formErrors.website && (
                  <div className="error">{formErrors.website}</div>
                )}
                <h6 className="mt-4">Whitepaper Link</h6>
                <label htmlFor="whitepaper">
                  Please include the link to your project whitepaper
                </label>
                <br />
                <input
                  type="text"
                  id="whitepaper"
                  name="whitepaper"
                  value={formValues.whitepaper}
                  onChange={handleInputChange}
                />
                {formErrors.whitepaper && (
                  <div className="error">{formErrors.whitepaper}</div>
                )}
                <h6 className="mt-4">
                  Submitter's Role<span>*</span>
                </h6>
                <label htmlFor="submitter">
                  Your relationship to this project eg. Founder, Developer,
                  Marketing Manager etc
                </label>
                <br />
                <input
                  type="text"
                  id="submitter"
                  name="submitter"
                  value={formValues.submitter}
                  onChange={handleInputChange}
                />
                {formErrors.submitter && (
                  <div className="error">{formErrors.submitter}</div>
                )}
              </div>
              <div className="basic-information mt-4">
                <h3>
                  Exchange Trading Information
                  <span>
                    <i>(Optional)</i>
                  </span>
                </h3>
                <p>
                  IMPORTANT NOTE: Please provide the EXACT LINK to the market
                  pairs. Failure to properly link to exchange pairs may result
                  in LISTING DELAYS. In case of AMM such as Uniswap, please link
                  to the analytics page of the pair i.e.,
                  <Link to="#">https://info.uniswap.org/tokens/[ADDRESS]</Link>
                </p>
                <h6 className="mt-4">Exchange Trade URL</h6>
                <textarea
                  id="exchangeTradeUrl"
                  name="exchangeTradeUrl"
                  value={formValues.exchangeTradeUrl}
                  onChange={handleInputChange}
                />
                {formErrors.exchangeTradeUrl && (
                  <div className="error">{formErrors.exchangeTradeUrl}</div>
                )}
              </div>
              <div className="basic-information mt-4">
                <h3>
                  Block Explorer Links
                  <span>
                    <i>(Optional)</i>
                  </span>
                </h3>
                <p>
                  Please provide explorer link(s) of the token. <br />
                  For example, if this is an ERC20 token please provide the
                  etherscan url eg.
                  https://etherscan.io/token/[token_contract_address]
                </p>
                <h6>Explorer Link</h6>
                <input
                  type="text"
                  id="explorerLink1"
                  name="explorerLink1"
                  value={formValues.explorerLink1}
                  onChange={handleInputChange}
                />
                <h6 className="mt-4">Explorer Link(2)</h6>
                <input
                  type="text"
                  id="explorerLink2"
                  name="explorerLink2"
                  value={formValues.explorerLink2}
                  onChange={handleInputChange}
                />
                <h6 className="mt-4">Explorer Link(3)</h6>
                <input
                  type="text"
                  id="explorerLink3"
                  name="explorerLink3"
                  value={formValues.explorerLink3}
                  onChange={handleInputChange}
                />
              </div>
              <div className="basic-information mt-4">
                <h3>
                  Contract Information
                  {/* <span>
                  <i>(Optional)</i>
                </span> */}
                </h3>
                <h6>Blockchain Platform</h6>
                <label htmlFor="tokenIssue">
                  What platform is this token issued on?
                </label>
                <br />
                <select
                  id="tokenIssue"
                  name="tokenIssue"
                  value={formValues.tokenIssue}
                  onChange={handleInputChange}
                >
                  <option value="Select your assets">Select your assets</option>
                  <option value="AELF">AELF</option>
                  <option value="Alex">Alex</option>
                  <option value="Zora">Zora</option>
                </select>
                <h6 className="mt-4">Contract Address</h6>
                <label htmlFor="contractAddress">
                  Please add token contract address (eg.
                  0xe41d2489571d322189246dafa5ebde1f4699f498 for Ethereum ERC-20
                  tokens)
                </label>
                <br />
                <input
                  type="text"
                  id="contractAddress"
                  name="contractAddress"
                  value={formValues.contractAddress}
                  onChange={handleInputChange}
                />
                {formErrors.contractAddress && (
                  <div className="error">{formErrors.contractAddress}</div>
                )}
                <h6 className="mt-4">Contract Decimal Places</h6>
                <label htmlFor="contractDecimals">
                  Here's how you can find the decimal places of a token
                  <Link to="#">
                    https://support.RWACAMP.com/hc/en-us/articles/5298401805337
                  </Link>
                </label>
                <br />
                <textarea
                  id="contractDecimals"
                  name="contractDecimals"
                  value={formValues.contractDecimals}
                  onChange={handleInputChange}
                />
                <hr />
                {/* <h6>Blockchain Platform(2)</h6>
              <label htmlFor="tokenIssue_2">
                What platform is this token issued on?
              </label>
              <br />
              <select
                id="tokenIssue_2"
                name="tokenIssue_2"
                value={formValues.tokenIssue_2}
                onChange={handleInputChange}
              >
                <option value="Select your assets">Select your assets</option>
                <option value="AELF">AELF</option>
                <option value="Alex">Alex</option>
                <option value="Zora">Zora</option>
              </select>
              <h6 className="mt-4">Contract Address</h6>
              <label htmlFor="contract_address_2">
                Please add token contract address (eg.
                0xe41d2489571d322189246dafa5ebde1f4699f498 for Ethereum ERC-20
                tokens)
              </label>
              <br />
              <input
                type="text"
                id="contract_address_2"
                name="contract_address_2"
                value={formValues.contract_address_2}
                onChange={handleInputChange}
              />
              <h6 className="mt-4">Contract Decimal Places</h6>
              <label htmlFor="contract_decimals_2">
                Here's how you can find the decimal places of a token
                <Link to="#">
                  https://support.RWACAMP.com/hc/en-us/articles/5298401805337
                </Link>
              </label>
              <br />
              <textarea
                id="contract_decimals_2"
                name="contract_decimals_2"
                value={formValues.contract_decimals_2}
                onChange={handleInputChange}
              />
              <hr /> */}
                {/* <h6>Blockchain Platform(3)</h6>
              <label htmlFor="tokenIssue_3">
                What platform is this token issued on?
              </label>
              <br />
              <select
                id="tokenIssue_3"
                name="tokenIssue_3"
                value={formValues.tokenIssue_3}
                onChange={handleInputChange}
              >
                <option value="Select your assets">Select your assets</option>
                <option value="AELF">AELF</option>
                <option value="Alex">Alex</option>
                <option value="Zora">Zora</option>
              </select>
              <h6 className="mt-4">Contract Address</h6>
              <label htmlFor="contract_address_3">
                Please add token contract address (eg.
                0xe41d2489571d322189246dafa5ebde1f4699f498 for Ethereum ERC-20
                tokens)
              </label>
              <br />
              <input
                type="text"
                id="contract_address_3"
                name="contract_address_3"
                value={formValues.contract_address_3}
                onChange={handleInputChange}
              />
              <h6 className="mt-4">Contract Decimal Places</h6>
              <label htmlFor="contract_decimals_3">
                Here's how you can find the decimal places of a token
                <Link to="#">
                  https://support.RWACAMP.com/hc/en-us/articles/5298401805337
                </Link>
              </label>
              <br />
              <textarea
                id="contract_decimals_3"
                name="contract_decimals_3"
                value={formValues.contract_decimals_3}
                onChange={handleInputChange}
              /> */}
              </div>
              {/* <div className="basic-information mt-4">
              <h3>
                Token Supply Details
                <span>
                  <i>(Optional)</i>
                </span>
              </h3>
              <h6 className="mt-4">Total Supply</h6>
              <p style={{ marginBottom: "0px" }}>
                <input
                  type="checkbox"
                  id="infinite_supply"
                  name="infinite_supply"
                  checked={formValues.infinite_supply}
                  onChange={handleInputChange}
                />
                Listing Terms * - I declare that I have read, understood and
                hereby fully agree to the terms and conditions stipulated at
                <Link to="#"> https://www.coingecko.com/en/listingTerm </Link>
                ("Listing T&Cs"), and that the company I represent has duly
                authorized me to enter into the Agreement on its behalf to
                severally and jointly bind the company and I.
              </p>
              <p className="mt-3 declaration-term">
                <input
                  type="checkbox"
                  id="supportTerm"
                  name="supportTerm"
                  checked={formValues.supportTerm}
                  onChange={handleInputChange}
                />
                Support Terms * - I understand that CoinGecko has the authority
                to assess the appropriateness of my request for the website at
                their own discretion. I understand that CoinGecko may accept or
                reject my request with no obligation to provide justification
                whether agreeable or otherwise.
              </p>
              <p>Tick the box above if total supply is infinite</p>
              <label htmlFor="tokenSupply">
                Maximum amount of tokens that can be minted (as per contract
                specification)
              </label>
              <br />
              <input
                type="text"
                id="tokenSupply"
                name="tokenSupply"
                value={formValues.tokenSupply}
                onChange={handleInputChange}
              />
            </div> */}
              <div className="basic-information mt-4">
                <h3>
                  Community Information
                  <span>
                    <i>(Optional)</i>
                  </span>
                </h3>
                <div className="profiles-portfolio-flex">
                  <div className="links-porfolio-form-block">
                    <h6>Twitter Profile Link</h6>
                    <label htmlFor="twitter">
                      eg. https://twitter.com/RWACAMP
                    </label>
                    <br />
                    <input
                      type="text"
                      id="twitter"
                      name="twitter"
                      value={formValues.twitter}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="links-porfolio-form-block">
                    <h6>Telegram Channel Link</h6>
                    <label htmlFor="telegram">eg. https://t.me/RWACAMP</label>
                    <br />
                    <input
                      type="text"
                      id="telegram"
                      name="telegram"
                      value={formValues.telegram}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <h6>Facebook Profile Link</h6>
                <label htmlFor="facebook">
                  Only accepts Facebook Pages, eg. https://www.facebook.com/RWA
                  Dog. Facebook groups are not accepted.
                </label>
                <br />
                <input
                  type="text"
                  id="facebook"
                  name="facebook"
                  value={formValues.facebook}
                  onChange={handleInputChange}
                />
                <h6>Youtube Channel Link</h6>
                <label htmlFor="youtube">
                  eg. https://www.youtube.com/c/RWACAMPTV
                </label>
                <br />
                <input
                  type="text"
                  id="youtube"
                  name="youtube"
                  value={formValues.youtube}
                  onChange={handleInputChange}
                />
                <h6>Subreddit Link</h6>
                <label htmlFor="subreddit">
                  eg. https://www.reddit.com/r/RWACAMP/
                </label>
                <br />
                <input
                  type="text"
                  id="subreddit"
                  name="subreddit"
                  value={formValues.subreddit}
                  onChange={handleInputChange}
                />
              </div>
              <div className="basic-information mt-4">
                <h3>
                  Developer Information
                  <span>
                    <i>(Optional)</i>
                  </span>
                </h3>
                <h6>Github</h6>
                <label htmlFor="github">eg. https://github.com/RWA-camp</label>
                <br />
                <input
                  type="text"
                  id="github"
                  name="github"
                  value={formValues.github}
                  onChange={handleInputChange}
                />
                <h6>Bitbucket</h6>
                <label htmlFor="bitbucket">
                  eg. https://bitbucket.org/devorclands/orc/src/master/
                </label>
                <br />
                <input
                  type="text"
                  id="bitbucket"
                  name="bitbucket"
                  value={formValues.bitbucket}
                  onChange={handleInputChange}
                />
              </div>
              <div className="basic-information mt-4">
                <label htmlFor="additionalInfo">
                  Any other info that you would like to share with the RWACAMP
                  team? Do write them here (Optional)
                </label>
                <br />
                <textarea
                  id="additionalInfo"
                  name="additionalInfo"
                  value={formValues.additionalInfo}
                  onChange={handleInputChange}
                />
              </div>
              <div className="basic-information mt-4">
                <h3>Attachments </h3>
                <h6>
                  Token Image<span>*</span>
                </h6>
                <label htmlFor="file_field">
                  Please upload attachment to new logo (PNG, JPG only).
                  Preferably transparent background, 200px x 200px
                </label>
                <br />
                <input
                  type="file"
                  id="file_field"
                  name="file_field"
                  onChange={handleFileChange}
                />
                {formErrors.tokenImage && (
                  <div className="error">{formErrors.tokenImage}</div>
                )}
              </div>
              <p className="mt-3 declaration-term">
                <input
                  type="checkbox"
                  id="listingTerm"
                  name="listingTerm"
                  // checked={formValues.listingTerm}
                  checked={formValues.listingTerm}
                  onChange={handleInputChange}
                />
                Listing Terms<span style={{ color: "red" }}>*</span> - I declare
                that I have read, understood and hereby fully agree to the terms
                and conditions stipulated at website
                {/* <Link to="#"> https://www.rwa-camps.netlify.app </Link>  */}
                ("Listing T&Cs"), and that the company I represent has duly
                authorized me to enter into the Agreement on its behalf to
                severally and jointly bind the company and I.
              </p>
              {formErrors.listingTerm && (
                <div className="error">{formErrors.listingTerm}</div>
              )}
              <p className="mt-3 declaration-term">
                <input
                  type="checkbox"
                  id="supportTerm"
                  name="supportTerm"
                  checked={formValues.supportTerm}
                  // checked={true}
                  onChange={handleInputChange}
                />
                Support Terms<span style={{ color: "red" }}>*</span> - I
                understand that RWACAMP has the authority to assess the
                appropriateness of my request for the website at their own
                discretion. I understand that RWACAMP may accept or reject my
                request with no obligation to provide justification whether
                agreeable or otherwise.
              </p>
              {formErrors.supportTerm && (
                <div className="error">{formErrors.supportTerm}</div>
              )}
              <div className="submit-btn">
                <button
                  style={{ fontSize: "smaller" }}
                  type="submit"
                  className="btn btn-success mt-1"
                  disabled={isSubmitting}
                >
                  Submit Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenForm;
