import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
// import AirdropImg from "../../Assets/img/airdrop-img-removebg-previewcropped.png";
import AirdropImg from "../../Assets/img/airdrop-img-removebg-preview.png";
import MockPortfolioMobile from "../../Assets/img/preview-mobile-mock-port-mobile-.png";
import "./MockPortfolio.css";
// import gsap from "gsap";

const NewMockBanner = () => {
  const [userName, setUserName] = useState(
    localStorage.getItem("name") ?? null
  );
  const videoRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && videoRef.current) {
        videoRef.current.play().catch((err) => {
          console.error("Autoplay failed:", err);
        });
      }
    };

    const handleUserInteraction = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((err) => {
          console.error("Playback blocked:", err);
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("touchstart", handleUserInteraction);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("touchstart", handleUserInteraction);
    };
  }, []);
  // const [splitText, setSplitText] = useState({ firstHalf: [], secondHalf: [] });
  // const [key, setKey] = useState(0);

  // useEffect(() => {
  //   const text = "RWA.GUIDE";
  //   const splittedText = text.split("");
  //   const halfLength = Math.floor(splittedText.length / 2);

  //   const firstHalf = splittedText.slice(0, halfLength);
  //   const secondHalf = splittedText.slice(halfLength);

  //   setSplitText({ firstHalf, secondHalf });

  //   if (window.gsap) {
  //     window.gsap.from(".banner .a", {
  //       y: 80,
  //       opacity: 0,
  //       duration: 2,
  //       delay: 0.2,
  //       stagger: 0.01,
  //     });
  //     window.gsap.from(".banner .b", {
  //       y: 80,
  //       opacity: 0,
  //       duration: 2,
  //       delay: 0.2,
  //       stagger: -0.1,
  //     });
  //   }
  // }, [key]);

  // useEffect(() => {
  //   setKey((prevKey) => prevKey + 1);
  // }, []);
  return (
    <div className="mock-portfolio-pg new-banner-mock">
      {/* <div className="bg-banner-full"></div> */}
      <video
        ref={videoRef}
        className="background-video"
        autoPlay
        muted
        loop
        playsInline
      >
        <source
          src="https://res.cloudinary.com/dbtsrjssc/video/upload/v1731503034/clideo_editor_0d0b04a107fc44c8a08f00c455f31518_online-video-cutter.com_2_rvfegj.mp4"
          type="video/mp4"
          className="mock-portfolio-img-2"
        />
        Your browser does not support the video tag.
      </video>
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="banner-port-text-new">
              <h1>
                {/* {splitText.firstHalf.map((char, index) => (
                  <span key={`first-${index}`} className="a">
                    {char}
                  </span>
                ))}
                {splitText.secondHalf.map((char, index) => (
                  <span key={`second-${index}`} className="b">
                    {char}
                  </span>
                ))} */}
                RWA.GUIDE
              </h1>
              <p>
                Keep track of your profits, losses and portfolio valuation with
                our easy to use platform
              </p>
            </div>
            {/* <div className="col-lg-12">
              <div className="banner-port-text">
                <h1>
                  {splitText.firstHalf.map((char, index) => (
                    <span key={`first-${index}`} className="a">
                      {char}
                    </span>
                  ))}
                  {splitText.secondHalf.map((char, index) => (
                    <span key={`second-${index}`} className="b">
                      {char}
                    </span>
                  ))}
                </h1>
                <div className="banner-right-text">
                  <p>
                    Keep track of your profits, losses and portfolio valuation
                    with our easy to use platform.
                  </p>{" "}
                </div>{" "}
              </div>
            </div> */}
            {/* <div className="col-lg-4">
              <div className="banner-right-text">
                Keep track of your profits, losses and portfolio valuation with
                our easy to use platform.
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>

    // </div>
  );
};

export default NewMockBanner;
