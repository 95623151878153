import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { interpolateBlues } from "d3-scale-chromatic";
import worldGeoJson from "./world-110m.json";
import * as d3 from "d3-scale";

export const countryData = [
  { country: "United States", code: "US", activeUsers: 1200 },
  { country: "India", code: "IN", activeUsers: 900 },
  { country: "Brazil", code: "BR", activeUsers: 750 },
  { country: "China", code: "CN", activeUsers: 1300 },
  { country: "Russia", code: "RU", activeUsers: 500 },
  { country: "United Kingdom", code: "GB", activeUsers: 800 },
  { country: "Germany", code: "DE", activeUsers: 950 },
  { country: "France", code: "FR", activeUsers: 600 },
  { country: "Italy", code: "IT", activeUsers: 450 },
  { country: "Canada", code: "CA", activeUsers: 700 },
  { country: "Australia", code: "AU", activeUsers: 850 },
  { country: "Mexico", code: "MX", activeUsers: 650 },
  { country: "South Africa", code: "ZA", activeUsers: 400 },
  { country: "Nigeria", code: "NG", activeUsers: 300 },
  { country: "Egypt", code: "EG", activeUsers: 200 },
  { country: "Argentina", code: "AR", activeUsers: 400 },
  { country: "Spain", code: "ES", activeUsers: 550 },
  { country: "Indonesia", code: "ID", activeUsers: 950 },
  { country: "Pakistan", code: "PK", activeUsers: 1100 },
  { country: "Bangladesh", code: "BD", activeUsers: 600 },
  { country: "Philippines", code: "PH", activeUsers: 750 },
  { country: "Thailand", code: "TH", activeUsers: 500 },
  { country: "Vietnam", code: "VN", activeUsers: 350 },
];

export const AdminUsersByCountry = () => {
  const userCounts = countryData.reduce((acc, { code, activeUsers }) => {
    acc[code] = activeUsers;
    return acc;
  }, {});

  const maxUsers = Math.max(
    ...countryData.map(({ activeUsers }) => activeUsers)
  );
  const colorScale = d3.scaleSequential(interpolateBlues).domain([0, maxUsers]);

  const [tooltip, setTooltip] = useState({
    visible: false,
    country: "",
    users: 0,
    x: 0,
    y: 0,
  });

  const handleMouseEnter = (event, isoCode, geo) => {
    // console.log("Geography properties:", geo.properties);
    const country = geo.properties.ADMIN || "Unknown";
    const activeUsers = userCounts[isoCode] || 0;
    const { clientX: x, clientY: y } = event;

    setTooltip({ visible: true, country, users: activeUsers, x, y });
  };

  const handleMouseLeave = () => setTooltip({ ...tooltip, visible: false });

  return (
    <div className="world-map-admin-dashboard" style={{ position: "relative" }}>
      <ComposableMap projection="geoMercator" className="map-padding">
        <Geographies geography={worldGeoJson}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isoCode = geo.properties.ISO_A2;
              const activeUsers = userCounts[isoCode] || 0;
              const fillColor = colorScale(activeUsers);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fillColor}
                  onMouseEnter={(event) =>
                    handleMouseEnter(event, isoCode, geo)
                  }
                  onMouseMove={(event) => handleMouseEnter(event, isoCode, geo)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    default: { outline: "none" },
                    hover: { fill: "rgb(78 116 238)", outline: "none" },
                    pressed: { fill: "#FF5722", outline: "none" },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>

      {/* Tooltip */}
      {tooltip.visible && (
        <div
          style={{
            position: "absolute",
            top: tooltip.y,
            left: tooltip.x,
            backgroundColor: "white",
            padding: "8px 5px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            pointerEvents: "none",
            zIndex: 10,
            fontSize: "10px",
          }}
        >
          <strong>{tooltip.country}</strong>
          <br />
          Active Users: <b>{tooltip.users}</b>
        </div>
      )}
    </div>
  );
};

export default AdminUsersByCountry;
