import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FeatuedArticles from "../../Components/FeaturesArticles/FeaturedArticles";
import { NavLink } from "react-router-dom";
import PlaceholderLoading from "react-placeholder-loading";
import * as API from "../../Utils/Services/api";
import "./BlogDetails.css";

const Blogs = () => {
  const navigate = useNavigate();
  const [blogList, setBlogList] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const resp = await API.GetBlog();
      setBlogList(resp.data.blog);
    })();
  }, []);
  return (
    <>
      {/* <FeatuedArticles /> */}
      <div className="container">
        <div className="row mt-3">
          <h2>Featured Articles</h2>
          {blogList && blogList.length > 0
            ? blogList.map((item, index) => (
                <div key={index} className="col-lg-3 mb-4">
                  <div>
                    <div
                      className="news-block new-news-block-hover"
                      onClick={() =>
                        navigate("/blogdetails", { state: { data: item } })
                      }
                    >
                      <div className="feauted-img">
                        {/* <img src="./Images/articles-img.webp" alt="" /> */}
                        <div className="featured-blog-img featured-blog-img-neww">
                          <img src={item.thumbnail} alt="category-img" />
                        </div>
                      </div>
                      <div className="news-box">
                        <div className="news-heading">
                          {item.title.slice(0, 40)}...
                          <span style={{ fontSize: "small", color: "grey" }}>
                            Read More
                          </span>
                        </div>
                        <div className="news-source">{item.author}</div>
                        <div className="news-time news-time-margin-zero">
                          {moment(item.publishDate).format("MMM Do YY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="col-lg-3 mb-2">
                  <div className="news-block">
                    <div>
                      <div className="feauted-img">
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={200}
                        />
                      </div>
                      <div className="news-box">
                        <PlaceholderLoading
                          shape="rect"
                          width="100%"
                          height={20}
                        />
                        <PlaceholderLoading
                          shape="rect"
                          width="80%"
                          height={20}
                          style={{ marginTop: 10 }}
                        />
                        <PlaceholderLoading
                          shape="rect"
                          width="60%"
                          height={20}
                          style={{ marginTop: 10 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;
