import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import AdminNavbar from "./AdminNavbar";
import AdminNewsTable from "./AdminNewsTable";
import {
  AdminAddNewsPost,
  AdminDeleteNews,
  AdminNewsFetch,
  AdminUpdateNews,
} from "../Utils/Services/api";
import QuillEditor from "./QuilEditor/BlogEditor";

export default function AdminNews() {
  const [newsPosts, setNewsPosts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [newPost, setNewPost] = useState({
    id: null,
    title: "",
    subTitle: "",
    content: "",
    slug: "",
  });
  const [thumbnail, setThumbnail] = useState(null);

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)+/g, "");
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await AdminNewsFetch({
        page: 1,
        size: 10,
        filter: "",
      });

      // console.log("Fetch response:", response); // Log the full response
      if (response.status) {
        setNewsPosts(response.data.news); // Set the news data to the state
      } else {
        throw new Error("Failed to fetch news posts");
      }
    } catch (error) {
      console.error("Error fetching news posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
  };

  const resetForm = () => {
    setNewPost({
      id: null,
      title: "",
      subTitle: "",
      content: "",
      slug: "",
    });
    setThumbnail(null);
    setIsEditMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPost((prev) => ({
      ...prev,
      [name]: value,
      slug: name === "title" ? generateSlug(value) : prev.slug,
    }));
  };

  const handleContentChange = (value) => {
    // console.log("Content updated:", value); // Logs the updated content from Quill editor
    setNewPost((prev) => ({
      ...prev,
      content: value,
    }));
  };

  const handleFileChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append("title", newPost.title);
    formData.append("subTitle", newPost.subTitle);
    formData.append("content", newPost.content);
    formData.append("slug", newPost.slug);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    try {
      let response;

      if (isEditMode && newPost.id) {
        response = await AdminUpdateNews(newPost.id, {
          ...newPost,
          thumbnail,
        });
      } else {
        response = await AdminAddNewsPost(formData);
      }

      if (response.status) {
        await fetchData();
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error submitting post:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEdit = (post) => {
    // console.log("Editing post:", post);

    setNewPost({
      id: post._id,
      title: post.title,
      subTitle: post.subTitle,
      content: post.content,
      slug: post.slug,
    });

    setThumbnail(null);
    setIsEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    try {
      await AdminDeleteNews(id);
      await fetchData();
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="admin-dashboard-pg">
        <div className="admin_news">
          <h3 className="text-center">News</h3>
          <div className="container">
            <div className="d-flex justify-content-end align-items-end">
              <Button
                className="mb-3 add_post_button"
                onClick={() => {
                  setIsEditMode(false);
                  handleShowModal();
                }}
              >
                Add News
              </Button>
            </div>
          </div>

          {loading ? (
            <div className="d-flex justify-content-center my-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <AdminNewsTable
              newsPosts={newsPosts}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          )}

          {/* Add/Edit News Modal */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {isEditMode ? "Edit News Post" : "Add News Post"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formTitle">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={newPost.title}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formSubtitle" className="mt-2">
                  <Form.Label>Subtitle</Form.Label>
                  <Form.Control
                    type="text"
                    name="subTitle"
                    value={newPost.subTitle}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formThumbnail" className="mt-2">
                  <Form.Label>Thumbnail Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="thumbnail"
                    onChange={handleFileChange}
                    required={!isEditMode} // Make thumbnail optional for editing
                  />
                </Form.Group>
                <Form.Group controlId="formContent" className="mt-2">
                  <QuillEditor
                    value={newPost.content} // Pass content to Quill editor
                    onChange={handleContentChange}
                  />
                </Form.Group>
                <Form.Group controlId="formSlug" className="mt-2 pt-4">
                  <Form.Label>Slug</Form.Label>
                  <Form.Control
                    type="text"
                    name="slug"
                    value={newPost.slug}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  disabled={submitting}
                >
                  {submitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  ) : isEditMode ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
