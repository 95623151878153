import React from "react";
import AdminNavbar from "./AdminNavbar";
import AdminGoogleAnalyticsGraph from "./AdminGoogleAnalyticsGraph";
import { AdminUsersByCountry, countryData } from "./AdminUsersByCountry";

export default function AdminDashboard() {
  const admingoogleAnalytics = [
    {
      adminDashCardHeading: "Active Users",
      adminCount: 86,
    },
    {
      adminDashCardHeading: "News Users",
      adminCount: 6,
    },
    {
      adminDashCardHeading: "Average Engagement Time ",
      adminCount: "9m 50s",
    },
    {
      adminDashCardHeading: "Total Revenue",
      adminCount: "$0",
    },
  ];

  return (
    <>
      <AdminNavbar />
      <div className="admin-dashboard-pg">
        <div className="container">
          <div className="row">
            <h2>Google Analytics</h2>
            {admingoogleAnalytics &&
              admingoogleAnalytics.map((data, i) => (
                <div className="col-lg-3" key={i}>
                  <div className="admin-google-analytics">
                    <div className="admin-google-analytics-heading">
                      {data.adminDashCardHeading}
                    </div>
                    <div className="admin-google-analytics-data">
                      {data.adminCount}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <br />
          <div className="row">
            <h4>Google Analytics Data Over Time</h4>
            <AdminGoogleAnalyticsGraph />
          </div>
          <br />
          <div className="row">
            <h4>Active users by Country</h4>
            <div className="col-lg-9">
              <AdminUsersByCountry />
            </div>

            <div className="col-lg-3">
              <div className="active-users-ui-list">
                <div className="country-name-count ">
                  <div className="adm-country-name-heading">Country</div>
                  <div className="adm-country-name-heading">Users</div>
                </div>
                {countryData &&
                  countryData.map((data, i) => (
                    <>
                      <div className="country-name-count country-name-count-data">
                        <div className="adm-country-name">{data.country}</div>
                        <div className="adm-country-count">
                          {data.activeUsers}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
