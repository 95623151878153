import { useEffect } from "react";
import "./Tooltip.css";

const Tooltip = ({ text }) => {
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="tooltip-btn">
      <button
        type="button"
        className="btn "
        style={{ padding: "0 0 0 5px" }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-custom-className="custom-tooltip"
        data-bs-title={text}
      >
        <i className="fa-solid fa-circle-info fa-xs "></i>
      </button>
    </div>
  );
};

export default Tooltip;
