import { SocialIcon } from "react-social-icons";
import Logo from "../../Assets/img/rwa-logo-footer.png";
import FooterAppStoreLogo from "../../Assets/img/footer-app-store.svg";
import FooterPlayStoreLogo from "../../Assets/img/footer-playstore.png";
import "./Footer.css";
const Footer = () => {
  const media = [
    {
      name: "Instagram",
      url: "https://www.instagram.com/rwa_camp/",
      network: "instagram",
    },
    {
      name: "Twitter",
      url: "https://x.com/rwa_camp",
      network: "x",
    },
    {
      name: "Telegram",
      url: "#",
      network: "telegram",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/@rwa_camp",
      network: "youtube",
    },
  ];
  return (
    <div className="footer-pg">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-12">
            <a className="navbar-brand navbar-brand-footer" href="/">
              {/* <h5> RWA CAMP</h5> */}
              <img src={Logo} alt="logo-footer" />
            </a>

            <div className="navbar-brand-desc">
              <p>
                RWA Camp is dedicated to providing educational resources and
                investment opportunities in real-world assets through
                tokenization. All investments carry risks, and it's important to
                conduct thorough due diligence before participating. The
                information provided by RWA Camp is for educational purposes
                only and should not be considered financial or legal advice.
              </p>
            </div>
          </div>
          <div className="col-lg-1 col-md-1"></div>
          <div className="col-lg-2 col-md-2 col-4">
            <h6>About</h6>
            <div className="about-links">
              <a href="/about">About Us</a>
              <a href="/blogs">Blogs</a>
              {/* <a href="/">Terms of Service</a>
            <a href="/">Privacy Policy</a>
            <a href="/">Ad Policy</a> */}
              <a href="/faq">FAQ</a>
              <a href="/">Learn (Coming soon)</a>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-4">
            <h6>Support</h6>
            <div className="support-links">
              {/* <a href="/">About Us</a>
            <a href="/">Blogs</a> */}
              <a href="/termsofservice">Terms of Service</a>
              <a href="/privacypolicy">Privacy Policy</a>
              <a href="/adprivacy">Ad Policy</a>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-4">
            <h6>Community</h6>
            <div className="support-links">
              {media.map((item, i) => (
                <SocialIcon
                  key={i}
                  target="_blank"
                  label={item.name}
                  url={item.url}
                  network={item.network}
                  className="media-content"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span>©RWA.GUIDE 2024 All rights reserved</span>
          <div className="download-app-img">
            <a href="">
              {" "}
              <img src={FooterPlayStoreLogo} alt="playstore" />{" "}
            </a>
            <a href="">
              {" "}
              <img src={FooterAppStoreLogo} alt="app-store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
