import React from "react";
import Button from "react-bootstrap/Button";
import EmptyPortfolioImg from "../../Assets/img/dashboard-img.webp";
import CustomModal from "../../Common/Modal/CustomModal";

const EmptyPortfolio = ({ setModalShow, setModalType }) => (
  <div className="empty-portfolio">
    {/* <h5 style={{ marginBottom: "0px" }}>My Portfolio</h5> */}
    <img
      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1721128718/DALL_E-2024-07-16-16.06.32---A-whimsical_-colorful-illustration-for-an-empty-page.-A-friendly-cartoon-character_-like-a-cheerful-robot_-riding-a-rocket-in-space.-The-robot-is-hold-_1_1_1_1_gfk8bg_ds7t2z.png"
      alt=""
    />
    <p>There's nothing here.</p>
    <div className="submit-btn mt-5">
      <Button
        onClick={() => {
          setModalShow(true);
          setModalType("addCoin");
        }}
      >
        Add Coin
      </Button>
    </div>
  </div>
);

export default EmptyPortfolio;
